<template>
  <q-menu v-bind="$attrs">
    <q-list>
      <template v-if="hasPermission('can.create.creditinvoices')">
        <q-item v-close-popup :disable="item.type.includes('credit')" clickable @click="creditInvoice">
          <q-item-section>{{ $t('credit_this_invoice') }} </q-item-section>
          <q-tooltip v-if="item.type.includes('credit')">
            {{ $t('cannot_credit_credit') }}
          </q-tooltip>
        </q-item>
        <q-separator />
      </template>
      <q-item v-if="!item.po_number" v-close-popup :disable="item.type.includes('credit')" clickable @click="$emit('addPo', item.number)">
        <q-item-section>{{ $t('add_purchase_order_number') }}</q-item-section>
        <q-tooltip v-if="item.type.includes('credit')">
          {{ $t('purchase_order.cannot_be_set') }}
        </q-tooltip>
      </q-item>
      <q-separator v-if="!item.po_number" />
      <q-item v-close-popup clickable @click="viewBooking">
        <q-item-section>{{ $t('booking.view') }}</q-item-section>
      </q-item>
      <q-item v-close-popup clickable @click="downloadPdf">
        <q-item-section> {{ $t('download_pdf') }} </q-item-section>
      </q-item>
    </q-list>
  </q-menu>
</template>
<script>
import { getPdfLink } from 'api/invoices'
import authentication from 'mixins/authentication'
export default {
  mixins: [authentication],
  props: ['item', 'selected'],
  methods: {
    creditInvoice () {
      this.$store.dispatch('finance/setInvoicesToCredit', [this.item])
      this.$router.push({ hash: '/invoice/sales-credit' })
    },
    downloadPdf () {
      var invoiceTab = window.open('', '_blank')
      getPdfLink(this.item.number)
        .then(res => {
          invoiceTab.location.href = res.data.link
        })
    },
    viewBooking () {
      const bookingRef = this.item.booking_references[0]
      this.$router.push({ path: '/bookings', hash: '/booking', query: { booking: bookingRef } })
    }
  }
}
</script>
